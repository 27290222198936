export default {
  "components-left-sidebar-components-sidebar-bottom": () => import("/app/src/layouts/_components/left-sidebar/_components/sidebar-bottom/index.ts").then(m => m.default || m),
  "components-left-sidebar-components-sidebar-bottom-sidebar-bottom": () => import("/app/src/layouts/_components/left-sidebar/_components/sidebar-bottom/sidebar-bottom.vue").then(m => m.default || m),
  "components-left-sidebar-components-sidebar-option-sidebar-option": () => import("/app/src/layouts/_components/left-sidebar/_components/sidebar-option/sidebar-option.vue").then(m => m.default || m),
  "components-left-sidebar": () => import("/app/src/layouts/_components/left-sidebar/index.ts").then(m => m.default || m),
  "components-left-left-sidebar": () => import("/app/src/layouts/_components/left-sidebar/left-sidebar.vue").then(m => m.default || m),
  "components-maintenance-banner": () => import("/app/src/layouts/_components/maintenance-banner/index.ts").then(m => m.default || m),
  "components-maintenance-maintenance-banner-constants": () => import("/app/src/layouts/_components/maintenance-banner/maintenance-banner.constants.ts").then(m => m.default || m),
  "components-maintenance-maintenance-banner": () => import("/app/src/layouts/_components/maintenance-banner/maintenance-banner.vue").then(m => m.default || m),
  "components-onboarding-banner": () => import("/app/src/layouts/_components/onboarding-banner/index.ts").then(m => m.default || m),
  "components-onboarding-onboarding-banner": () => import("/app/src/layouts/_components/onboarding-banner/onboarding-banner.vue").then(m => m.default || m),
  "components-right-sidebar-components-expandable-list-expandable-list-item": () => import("/app/src/layouts/_components/right-sidebar/_components/expandable-list/expandable-list-item.vue").then(m => m.default || m),
  "components-right-sidebar-components-expandable-list-expandable-list": () => import("/app/src/layouts/_components/right-sidebar/_components/expandable-list/expandable-list.vue").then(m => m.default || m),
  "components-right-sidebar-components-expandable-list": () => import("/app/src/layouts/_components/right-sidebar/_components/expandable-list/index.ts").then(m => m.default || m),
  "components-right-sidebar": () => import("/app/src/layouts/_components/right-sidebar/index.ts").then(m => m.default || m),
  "components-right-right-sidebar": () => import("/app/src/layouts/_components/right-sidebar/right-sidebar.vue").then(m => m.default || m),
  "components-sidebar": () => import("/app/src/layouts/_components/sidebar/index.ts").then(m => m.default || m),
  "components-sidebar-sidebar": () => import("/app/src/layouts/_components/sidebar/sidebar.vue").then(m => m.default || m),
  "components-top-navigation-components-locale-switcher": () => import("/app/src/layouts/_components/top-navigation/_components/locale-switcher/index.ts").then(m => m.default || m),
  "components-top-navigation-components-locale-switcher-locale-switcher": () => import("/app/src/layouts/_components/top-navigation/_components/locale-switcher/locale-switcher.vue").then(m => m.default || m),
  "components-top-navigation": () => import("/app/src/layouts/_components/top-navigation/index.ts").then(m => m.default || m),
  "components-top-top-navigation": () => import("/app/src/layouts/_components/top-navigation/top-navigation.vue").then(m => m.default || m),
  centered: () => import("/app/src/layouts/centered.vue").then(m => m.default || m),
  default: () => import("/app/src/layouts/default.vue").then(m => m.default || m)
}